import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";

// import { Blog } from './pages';

import { AnimatePresence } from "framer-motion";
import Loading from "./components/Loading.js";

const NouvelleTerapia = lazy(() => import("./pages/NouvelleTerapia.js"));
const Contacto = lazy(() => import("./pages/Contacto.js"));
const Clases = lazy(() => import("./pages/Clases.js"));
const CursosMamas = lazy(() => import("./pages/CursosMamas.js"));
const Terapeuta = lazy(() => import("./pages/Terapeuta.js"));
const Sesiones = lazy(() => import("./pages/Sesiones.js"));
const Regalo = lazy(() => import("./pages/Regalo.js"));
const PoliticaDePrivacidad = lazy(() =>
  import("./pages/PoliticaDePrivacidad.js")
);
const CondicionesDeVenta = lazy(() => import("./pages/CondicionesdeVenta.js"));
const PoliticaDeCookies = lazy(() => import("./pages/PoliticaDeCookies.js"));
const AvisoLegal = lazy(() => import("./pages/AvisoLegal.js"));
const FisioTerapia = lazy(() => import("./pages/Fisioterapia.js"));
const FisioterapiaParaMayores = lazy(() =>
  import("./pages/FisioterapiaParaMayores.js")
);
const FisioterapiaEmpresas = lazy(() =>
  import("./pages/FisioterapiaParaEmpresas.js")
);
const FisioterapiaPostQuirurgica = lazy(() =>
  import("./pages/FisioterapiaPostQuirurgica.js")
);
const FisioterapiaNeuro = lazy(() => import("./pages/FisioterapiaNeuro.js"));
const FisioterapiaADomicilio = lazy(() =>
  import("./pages/FisioterapiaADomicilio.js")
);
const FisioterapiaRespiratoriaAdulta = lazy(() =>
  import("./pages/FisioterapiaRespiratoriaAdulta.js")
);
// const FisioterapiaRespiratoriaPediatrica = lazy(() =>
//   import("./pages/FisioterapiaRespiratoriaPediatrica.js")
// );
const FisioterapiaParaBailarines = lazy(() =>
  import("./pages/FisioterapiaParaBailarines.js")
);

const FisioterapiaSuelvoPelvico = lazy(() =>
  import("./pages/FisioterapiaSuelvoPelvico.js")
);

const Osteopatia = lazy(() => import("./pages/Osteopatia.js"));
const LowPressureFitness = lazy(() => import("./pages/LowPressureFitness.js"));
const Nutricion = lazy(() => import("./pages/Nutricion.js"));
const Terapiaemocional = lazy(() => import("./pages/Terapiaemocional.js"));
const EmbarazoParto = lazy(() => import("./pages/EmbarazoParto.js"));
const NotFound = lazy(() => import("./pages/NotFound.js"));

const Blog = lazy(() => import("./pages/blog/index.js"));
const Eventos = lazy(() => import("./pages/eventos/index.js"));
const BlogCategory = lazy(() => import("./pages/blog/BlogCategory.js"));
const EventosCategory = lazy(() =>
  import("./pages/eventos/EventosCategory.js")
);

//Blog articles
const NutricionEmbarazo = lazy(() =>
  import("./pages/blog/nutricionDuranteEmbarazo.js")
);

const CursoPartoPostParto = lazy(() =>
  import("./pages/blog/CursosPartoPostParto.js")
);

const SesionesOnline = lazy(() => import("./pages/blog/sesionesOnline.js"));
// const tagManagerArgs = {
//   gtmId: 'GTM-5WP4FTG'
// }
// TagManager.initialize(tagManagerArgs)
// ReactGA.initialize("G-0FEQKGKHM5")

function RedirectTo(newPath) {
  return <Navigate to={newPath} replace />;
}

function App() {
  window.dataLayer.push({
    event: "pageview",
  });

  return (
    <AnimatePresence mode="wait">
      <BrowserRouter>
        <Routes>
          <Route
            index
            path="/"
            element={
              <Suspense fallback={<Loading />}>
                <NouvelleTerapia />
              </Suspense>
            }
          ></Route>
          <Route
            path="/contacto"
            element={
              <Suspense>
                <Contacto />
              </Suspense>
            }
          ></Route>

          <Route path="/sesiones" element={RedirectTo("/sesiones-valencia")} />
          <Route
            path="/sesiones-valencia"
            element={
              <Suspense>
                <Sesiones />
              </Suspense>
            }
          ></Route>

          <Route
            path="/terapeuta"
            element={RedirectTo("/terapeuta-valencia")}
          />
          <Route
            path="/terapeuta-valencia"
            element={
              <Suspense>
                <Terapeuta />
              </Suspense>
            }
          ></Route>
          <Route path="/cursos" element={RedirectTo("/")} />
          <Route
            path="/clases-lowpressurefitness-valencia"
            element={
              <Suspense>
                <Clases />
              </Suspense>
            }
          ></Route>
          <Route
            path="/cursos-embarazo-parto-postparto-valencia"
            element={
              <Suspense>
                <CursosMamas />
              </Suspense>
            }
          ></Route>
          <Route
            path="/bono-tarjeta-regalo-valencia"
            element={
              <Suspense>
                <Regalo />
              </Suspense>
            }
          />
          <Route
            path="/politicadeprivacidad"
            element={
              <Suspense>
                <PoliticaDePrivacidad />
              </Suspense>
            }
          />
          <Route
            path="/politicadecookies"
            element={
              <Suspense>
                <PoliticaDeCookies />
              </Suspense>
            }
          />
          <Route
            path="/avisolegal"
            element={
              <Suspense>
                <AvisoLegal />
              </Suspense>
            }
          />
          <Route
            path="/condiciones-generales-de-venta"
            element={<CondicionesDeVenta />}
          />
          <Route
            path="/fisioterapia-valencia"
            element={
              <Suspense>
                <FisioTerapia />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-geriatrica"
            element={RedirectTo("/fisioterapia-geriatrica-valencia")}
          />
          <Route
            path="/fisioterapia-geriatrica-valencia"
            element={
              <Suspense>
                <FisioterapiaParaMayores />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-para-empresas"
            element={RedirectTo("/fisioterapia-empresas-valencia")}
          />

          <Route
            path="/fisioterapia-empresas-valencia"
            element={
              <Suspense>
                <FisioterapiaEmpresas />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-para-enfermedades-neurologicas"
            element={RedirectTo("/fisioterapia-neurologica-valencia")}
          />
          <Route
            path="/fisioterapia-post-quirurgica"
            element={
              <Suspense>
                <FisioterapiaPostQuirurgica />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-para-enfermedades-neurologicas"
            element={RedirectTo("/fisioterapia-neurologica-valencia")}
          />

          <Route
            path="/fisioterapia-neurologica-valencia"
            element={
              <Suspense>
                <FisioterapiaNeuro />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-a-domicilio"
            element={RedirectTo("/fisioterapia-a-domicilio-valencia")}
          />
          <Route
            path="/fisioterapia-a-domicilio-valencia"
            element={
              <Suspense>
                <FisioterapiaADomicilio />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-repiratoria"
            element={RedirectTo("/fisioterapia-repiratoria-valencia")}
          />
          <Route
            path="/fisioterapia-repiratoria-valencia"
            element={
              <Suspense>
                <FisioterapiaRespiratoriaAdulta />
              </Suspense>
            }
          />
          {/* <Route
            path="/fisioterapia-respiratoria-pediatrica-valencia"
            element={
              <Suspense>
                <FisioterapiaRespiratoriaPediatrica />
              </Suspense>
            }
          /> */}

          <Route
            path="/fisioterapia-para-bailarines"
            element={RedirectTo("/fisioterapia-para-bailarines-valencia")}
          />
          <Route
            path="/fisioterapia-para-bailarines-valencia"
            element={
              <Suspense>
                <FisioterapiaParaBailarines />
              </Suspense>
            }
          />

          <Route
            path="/fisioterapia-suelo-pelvico-valencia"
            element={
              <Suspense>
                <FisioterapiaSuelvoPelvico />
              </Suspense>
            }
          />
          <Route
            path="/osteopatia"
            element={RedirectTo("/osteopatia-valencia")}
          />
          <Route
            path="/osteopatia-valencia"
            element={
              <Suspense>
                <Osteopatia />
              </Suspense>
            }
          />
          <Route path="/nutricion" element={RedirectTo("/pni-valencia")} />
          <Route
            path="/pni-valencia"
            element={
              <Suspense>
                <Nutricion />
              </Suspense>
            }
          />
          <Route
            path="/terapiaemocional"
            element={RedirectTo("/terapiaemocional-valencia")}
          />
          <Route
            path="/terapiaemocional-valencia"
            element={
              <Suspense>
                <Terapiaemocional />
              </Suspense>
            }
          />
          <Route
            path="/lowpressurefitness"
            element={RedirectTo("/lowpressurefitness-valencia")}
          />
          <Route
            path="/lowpressurefitness-valencia"
            element={
              <Suspense>
                <LowPressureFitness />
              </Suspense>
            }
          />
          <Route
            path="/embarazo-parto-postparto-valencia"
            element={
              <Suspense>
                <EmbarazoParto />
              </Suspense>
            }
          />
          <Route
            path="/blog"
            element={
              <Suspense>
                <Blog />
              </Suspense>
            }
          />
          <Route
            path="/blog/nutricion-durante-embarazo"
            element={
              <Suspense>
                <NutricionEmbarazo />
              </Suspense>
            }
          />
          <Route
            path="/blog/sesiones-online"
            element={
              <Suspense>
                <SesionesOnline />
              </Suspense>
            }
          />
          <Route
            path="/blog/cursos-preparacion-parto-postparto-valencia"
            element={
              <Suspense>
                <CursoPartoPostParto />
              </Suspense>
            }
          />

          <Route
            path="/blog/categoria/:categoryName"
            element={
              <Suspense>
                <BlogCategory />
              </Suspense>
            }
          />
          <Route
            path="/eventos"
            element={
              <Suspense>
                <Eventos />
              </Suspense>
            }
          />
          <Route
            path="/eventos/categoria/:categoryName"
            element={
              <Suspense>
                <EventosCategory />
              </Suspense>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>{" "}
      </BrowserRouter>
    </AnimatePresence>
  );
}

export default App;
